<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                                        <label for="code">Mã lái xe</label>
                                        <md-input name="code" v-model="entity.code"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập mã lái xe</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="license">Bằng lái xe</label>
                                        <md-input name="license" v-model="entity.license"></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.entity.vehicleId.$error }">
                                    <label>Phương tiện</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.vehicleId.isSelected">Vui lòng chọn phương tiện/thiết bị</span>
                                </md-autocomplete>
                                <md-button @click="openVehicle()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm phương tiện</md-tooltip>
                                </md-button>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.firstName.$error }">
                                        <label for="first-name">Họ và tên đệm</label>
                                        <md-input name="first-name" v-model="entity.firstName" ></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.firstName.required">Vui lòng nhập họ và tên đệm</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.lastName.$error }">
                                        <label for="last-name">Tên</label>
                                        <md-input name="last-name" v-model="entity.lastName" ></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.lastName.required">Vui lòng nhập tên</span>
                                    </md-field>
                                </div>
                            </div>
                        </div>   
                        <div class="col l-4 m-4 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="phone">Số điện thoại</label>
                                        <md-input name="phone" v-model="entity.phone" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="identityNumber">CMTND</label>
                                        <md-input name="identityNumber" v-model="entity.identityNumber" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="email">Email</label>
                                        <md-input name="email" v-model="entity.email" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="position">Chức vụ</label>
                                        <md-input name="position" v-model="entity.position" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <md-datepicker v-model="entity.dateOfBirth" :md-model-type="String">
                                <label>Ngày sinh</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="radio-group">
                                <label>Lái xe/phụ xe</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isAssistantDriver" :value="false">Lái xe</md-radio>
                                    <md-radio v-model="entity.isAssistantDriver" :value="true">Phụ xe</md-radio>
                                </div>
                            </div>
                            <md-field class="number-control">
                                <div>Lương cơ bản:</div>
                                <number name="salarybase" v-model="entity.salaryBase" v-bind="number"></number> 
                            </md-field>
                            <md-field class="number-control">
                                <div>Phụ cấp:</div>
                                <number name="allowance" v-model="entity.allowance" v-bind="number"></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="deptName" @md-selected="getDeptSelected" :md-options="depts" @md-changed="getDepts"  @md-opened="getDepts" :class="{'md-invalid': submitted && $v.entity.departmentId.$error }">
                                    <label>Phòng ban</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.departmentId.isSelected">Vui lòng chọn phòng ban</span>
                                </md-autocomplete>
                                <md-button @click="openDept()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="address">Địa chỉ</label>
                                <md-input name="address" v-model="entity.address" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="radio-group">
                                <label>Hoạt động/khóa</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <deptList ref="deptList" @close="closeDept"/>
    </md-dialog>
</template>

<script>
    import driverService from '../../../api/driverService';
    import vehicleService from '../../../api/vehicleService';
    import departmentService from '../../../api/departmentService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue'

    export default ({
        components: {
            vehicleList,
            deptList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật lái xe'
        },
        data() {
            return {
                title: '',
                id: 0,
                showDialog: false,
                saveAndCreate: false,
                submitted: false,
                entity: { id: 0, departmentId: 0, salaryBase: 0, license: '', allowance: 0, isDriver: true, isAssistantDriver: false, code: '', vehicleId: 0, firstName: '', lastName: '', address: '', phone: '', email: '', position: '', identityNumber: '', dateOfBirth: null, isDeleted: false, isActive: true },
                vehicleName: '',
                vehicles: [],
                number: {   
                    decimal: '.',
                    separator: ',',
                    suffix: ' VNĐ',
                    precision: 2,
                    masked: false,
                    nullValue: ''
                },
                depts: [],
                deptName: ''
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật lái xe';
               this.getById();
            }
            else{
               this.title = 'Thêm mới lái xe';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới lái xe';
                    this.vehicleName = '';
                    this.deptName = '';
                    this.entity = { id: 0, departmentId: 0, salaryBase: 0, license: '', allowance: 0, isDriver: true, isAssistantDriver: false, code: '', vehicleId: 0, firstName: '', lastName: '', address: '', phone: '', email: '', position: '', identityNumber: '', dateOfBirth: null, isDeleted: false, isActive: true };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật lái xe';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
                this.setLoading(false);
            },

            close(){
                this.showDialog = false;
            },

            closeDept(item){
                this.deptName = item.name;
                this.entity.departmentId = item.id;
                this.$refs.deptList.close();
            },

            getDeptSelected(val){
                this.entity.departmentId = val.id;
                this.deptName = val.name;
            },

            openDept(){
                this.$refs.deptList.open();
            },

            getDepts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                departmentService.getDepartments(search).then((response) => {
                    if(response.statusCode == 200){
                        this.depts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.entity.vehicleId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.entity.vehicleId = val.id;
                this.vehicleName = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.entity.$touch();
                if (this.$v.entity.$invalid) {
                    return;
                }
                if (this.addLogin == true && this.account.id == 0 && this.$v.account.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
                this.$emit('close', this.saveAndCreate);
            },

            add(){
                this.setLoading(true);
                driverService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới lái xe thành công");
                        if(!this.saveAndCreate){
                            this.$router.push('/driver');
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                driverService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/driver');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                driverService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.vehicleName = this.entity.vehicle.licensePlate;
                        if(this.entity.department !== null){
                            this.deptName = this.entity.department.name;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/driver');
            },
        },
        watch: { 
            vehicleName: function (val) { 
                if(val == ''){
                    this.entity.vehicleId = 0;
                }
            },
            deptName: function (val) { 
                if(val == ''){
                    this.entity.departmentId = 0;
                }
            }
        },
        validations: {
            entity: {
                code: { required },
                vehicleId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                departmentId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                firstName: { required },
                lastName: { required }
            }
        }
    })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 1000px;
        height: 430px;
        max-width: 1000px;
    }
    .md-scrollbar {
        height: 340px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>